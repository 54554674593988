/* eslint-disable semi,no-undef,curly,no-multiple-empty-lines,no-trailing-spaces,indent,no-tabs,no-unused-vars */

var animationEnabled = true;
var animationFrame = null;
var inTransition = false
var pageList = ['_landing', '_home', '_about', '_services', '_ourwork', '_contact']
var baseFontSize = parseInt(getComputedStyle(document.querySelector(':root')).getPropertyValue('--base-font-size'));

$(function () {
	enableScroll();
	serviceHoverFunction();

	$('.nav li').on('click', navigate);

	$(document).on('keydown', function (event) {
		if (event.which === 9) {
			document.getElementById('aSkipNavigation').style.setProperty('visibility', 'visible');
		}
		//
		// if the user presses the space bar while the skip navigation button has the focus, execute the skipNavigation function
		// which will move focus to the first element after the navigation structure
		//
		if (event.code === 'Space') {
			var dummyEl = document.getElementById('aSkipNavigation');
			var isFocused = (document.activeElement === dummyEl);
			if (isFocused) {
				skipNavigation('aSkipNavigation', 2);
			}
		}

		if (event.code === 'Space') {
			document.getElementById('aSkipNavigation').style.setProperty('visibility', 'visible');
		}
	});

	$(document).on('mouseup', function (event) {
		document.getElementById('aSkipNavigation').style.setProperty('visibility', 'hidden');
	});
	//
	// if the focus is on a navigation list item and the enter key is pressed
	// execute the navigate function
	//
	$('.nav li').keypress(function (event) {
		if (event.which === 13) {
			$('.nav li').on('keypress', navigateKeyPress);
		}
	});

	$('.mobile-nav li').on('click', navigate);
	$('.mobile-menu-button').on('click', openMenu);
	$('.mobile-menu-close').on('click', closeMenu);
	$('.mobile-nav ul li').on('click', closeMenu);

	$('.collapse-category').on('shown.bs.collapse', function (e) { setArrowRotation(e.currentTarget, true) });
	$('.collapse-category').on('hidden.bs.collapse', function (e) { setArrowRotation(e.currentTarget, false) });

//
// the following code was removed.  The functionality was placed in the popup.  see /partials/_footer.html
//
//	document.addEventListener("keydown", function (e) {
//		if (e.altKey && e.key === 's') {
//			$('#fontAdjust').toggle()
//		}
//	})

})

function setArrowRotation (element, rotated) {
	var id = element.id;
	var arrow = $('button[data-target="#' + id + '"] .fa-arrow-up');
	if (rotated) {
		arrow.css('transform', 'rotate(180deg)');
	} else {
		arrow.css('transform', 'rotate(0deg)');
	}
}

function previousPage () {
	var index = $('.nav .active').index();
	var $navList = $('.nav li');

	index--;
	if (index < 0) { index = 0 }
	if (index > $navList.length) { index = $navList.length }

	var previousPage = $($navList[index]).data('page');
	$('.nav li[data-page="' + previousPage + '"]').trigger('click');
}

function nextPage () {
	var index = $('.nav .active').index();
	var $navList = $('.nav li');

	index++;
	if (index < 0) { index = 0 }
	if (index > $navList.length) { index = $navList.length }

	var next = $($navList[index]).data('page');
	$('.nav li[data-page="' + next + '"]').trigger('click');
}

function openMenu () {
	var $mobileMenu = $('.mobile-nav');
	if ($mobileMenu.hasClass('active-menu'))
		return;
	$mobileMenu.addClass('active-menu');
}

function closeMenu () {
	var $mobileMenu = $('.mobile-nav');
	if (!$mobileMenu.hasClass('active-menu'))
		return;
	$mobileMenu.removeClass('active-menu');
	$mobileMenu.addClass('close-menu');

	$mobileMenu.on('animationend', function (e) {
		$mobileMenu.removeClass('close-menu');
	})
}

function pageButtonClicked (page) {
	$('.nav li[data-page="' + page + '"]').trigger('click');
}

function navigateKeyPress (sender) {
	navigate(sender, 1);
}

function navigate (sender, whichFunction) {
  if ($(sender.target).hasClass('active') || inTransition)
    return;
  inTransition = true;

  var $pagelist = $('.page-list');
  var activePage = $pagelist.data('active');
	var page = $(sender.target).data('page');

	var animateLogoOut = activePage === 0;
	var animateLogoIn = pageList.indexOf(page) === 0;

  if (activePage > pageList.indexOf(page))
    animatePageFromTop(page, $pagelist, sender, animateLogoIn, whichFunction);
  else
    animatePageFromBottom(page, $pagelist, sender, animateLogoOut, whichFunction);

  $pagelist.data('active', pageList.indexOf(page));
}

function animatePageFromTop (page, $pagelist, sender, animateLogoIn, whichFunction) {
	if (animationFrame)
		cancelAnimationFrame(animationFrame); // stops canvas from running once page changes (prevents stacking of animation frames)

	//
	// remove the active class from the current navigation li element
	//
	$('.nav li').removeClass('active');
	$('.mobile-nav li').removeClass('active');

	//
	// if whichFunction != null, we reached this point via keypress navigation so we want to
	// set the focus to the skip navigation link
	//
	if (whichFunction != null) {
		document.getElementById('aSkipNavigation').style.setProperty('visibility', 'visible');
		document.getElementById('aSkipNavigation').focus();
	}

	$('[data-page="' + page + '"]').addClass('active');

	$('.current-page').addClass('previous-page-below');
	$pagelist.append('<li class="next-page-above"></li>');
	if (animateLogoIn)
		$('.scr-logo').addClass('animate-logo-in');

	var $nextpage = $('.next-page-above');
	var $previouspage = $('.previous-page-below');
	$nextpage.load('./partials/' + page + '.html');

	if (animateLogoIn) {
		$('.scr-logo').addClass('animate-logo-in');
		$('.tagline').addClass('animate-tagline-in');
	}

	replaceState(page.split('_')[1]);

	// On keyframe animation end
	$previouspage.on('animationend', function (e) {
		$previouspage.remove()
	})
	$nextpage.on('animationend', function (e) {
		inTransition = false
		removeAnimations($nextpage);
		// $('.mobile-services-info').fadeIn();
	})
}

function animatePageFromBottom (page, $pagelist, sender, animateLogoOut, whichFunction) {
	if (animationFrame)
		cancelAnimationFrame(animationFrame); // stops canvas from running once page changes (prevents stacking of animation frames)

	$('.nav li').removeClass('active');
	$('.mobile-nav li').removeClass('active');

	//
	// if whichFunction != null, we reached this point via keypress navigation so we want to
	// set the focus to the skip navigation link
	//
	if (whichFunction != null) {
		document.getElementById('aSkipNavigation').style.setProperty('visibility', 'visible');
		document.getElementById('aSkipNavigation').focus();
	}

	$('[data-page="' + page + '"]').addClass('active');

	$('.current-page').addClass('previous-page-above');
	$pagelist.append('<li class="next-page-below"></li>');

	var $nextpage = $('.next-page-below');
	var $previouspage = $('.previous-page-above');
	$nextpage.load('./partials/' + page + '.html');
	if (animateLogoOut) {
		$('.scr-logo').addClass('animate-logo-out');
		$('.tagline').addClass('animate-tagline-out');
	}

	replaceState(page.split('_')[1]);

	// On keyframe animation end
	$previouspage.on('animationend', function (e) {
		$previouspage.remove()
	})
	$nextpage.on('animationend', function (e) {
		inTransition = false
		removeAnimations($nextpage);
		// $('.mobile-services-info').fadeIn();
	})
}

function removeAnimations ($nextpage) {
	$nextpage.addClass('current-page').removeClass('next-page-above next-page-below');
	$('.scr-logo').removeClass('animate-logo-out animate-logo-in');
	$('.tagline').removeClass('animate-tagline-in animate-tagline-out');
}

function pageScroll (sender) {
  var nextIndex = $('.nav .active').index()
	var $navLinks = $('.nav li');

  if (sender.originalEvent.wheelDelta > 0) { nextIndex-- } else { nextIndex++ }

  if (nextIndex < 0) { nextIndex = 0 }
  if (nextIndex > $navLinks.length) { nextIndex = $navLinks.length }

  var page = $($navLinks[nextIndex]).data('page');

	$('.nav li[data-page="' + page + '"]').trigger('click');
}

function forceUnhover (passedElement) {
	//
	// get the element that needs to get covered
	//
	var thisElement = document.getElementById(passedElement);
	//
	// slide the element down
	//
	$(thisElement).find('.service-image').slideDown('fast');
	$(thisElement).find('.service-list').slideUp('fast');
	$(thisElement).find('.service-title').css('color', '#0c4e68');
}

function forceHover (passedElement) {
	//
	// ensure that any service that was slide up gets slide down
	//
	forceUnhover('divIdentity')
	forceUnhover('divDesign')
	forceUnhover('divDevelopment')
	forceUnhover('divSupport')
	//
	// get the element that the user pressed ENTER on
	//
	var thisElement = document.getElementById(passedElement);
	//
	// slide the element that the user pressed ENTER on down
	//
	$(thisElement).find('.service-image').slideUp('fast');
	$(thisElement).find('.service-list').slideDown('fast');
	$(thisElement).find('.service-title').css('color', '#f05b2a');
}

function serviceHoverFunction () {
	$(document).on('mouseenter', '.service-container', function () {
		$(this).find('.service-image').slideUp('fast');
		$(this).find('.service-list').slideDown('fast');
		$(this).find('.service-title').css('color', '#f05b2a');
	});

	$(document).on('mouseleave', '.service-container', function () {
		$(this).find('.service-image').slideDown('fast');
		$(this).find('.service-list').slideUp('fast');
		$(this).find('.service-title').css('color', '#0c4e68');
	});
}

//
// function is called when user keypresses to a button and the button gets the focus.  We make the button larger
// and draw a border around the button to indicate focus to the user
//
function addGrow (passedThis, passedThat) {
	document.getElementById(passedThis).style.setProperty('transform', 'scale(1.1)');
	document.getElementById(passedThat).style.border = 'solid thin #000';
}
//
// function is called when a button that has the focus via keypress looses focus.  We make the button normal
// size and remove the border so the button no longer appears to have the focus
//
function removeGrow (passedThis, passedThat) {
	document.getElementById(passedThis).style.setProperty('transform', 'scale(1.0)');
	document.getElementById(passedThat).style.border = '';
}
//
// function will be called if the 'skip navigation' <a> gets the focus
//
// passedThis is the element that has the focus
// whichFunction = 0 ==> set the border attributes of the element
// whichFunction = 1 ==> remove the border attributes of the element
// whichFunction = 2 ==> set the focus to the first element after the navigation structure
//
function skipNavigation (passedThis, whichFunction) {
	console.log(whichFunction);
	if (whichFunction === 0) {
		document.getElementById('divSkipNavigation').style.display = 'hidden';
		document.getElementById(passedThis).style.border = 'solid thin #000';
	} else {
		if (whichFunction === 1) {
			document.getElementById('divSkipNavigation').style.display = 'initial';
			document.getElementById(passedThis).style.border = '';
		} else {
			var element;
			var index = $('.nav .active').index();
			var $navList = $('.nav li');
			var page = $($navList[index]).data('page');
			switch (page) {
				case '_home':
					element = document.getElementById('btnCallUs').focus();
					break;
				case '_about':
					element = document.getElementById('btnOurServices').focus();
					break;
				case '_services':
					element = document.getElementById('imgIdentity').focus();
					break;
				case '_ourwork':
					element = document.getElementById('imgOurWork').focus();
					break;
				case '_contact':
					element = document.getElementById('aContactUs').focus();
					break;
				default:
					break;
			}
		}
	}
}

// function showService (sender) {
// 	var $activatedDiv = $(sender);
// 	// if (!$activatedDiv.hasClass('half-opacity-mobile')) { return; }
//
// 	var service = $activatedDiv.data('service');
//
// 	var $mobileServiceInfo = $('.mobile-services-info');
// 	$('.mobile-services-info > .popup-list').hide();
// 	$('.desktop-support').hide();
// 	$('.' + service).show().css({"display": "flex", "align-items": "center", "z-index": "999"});
// 	$('.' + service ).css({"animation": "fadein 500ms"});
//
// 	$mobileServiceInfo.fadeIn();
//
// 	$('.service-title').css("color", "#0c4e68");
// 	$(sender).find('.service-title').css('color', '#f05b2a');
//
//
// 	// $mobileServiceInfo.addClass('animate-service-info-right');
//
// 	// $('.service-info').addClass('half-opacity-mobile');
// 	// $('.service-info[data-service="' + service + '"]').removeClass('half-opacity-mobile');
//
//
// 	// $mobileServiceInfo.on('animationend', function (e) {
// 	// 	$('.mobile-services-info > div').hide();
// 	// 	$('.' + service).show();
// 	//
// 	// 	$mobileServiceInfo.removeClass('animate-service-info-right').off();
// 	// 	$mobileServiceInfo.addClass('animate-service-info-left');
// 	//
// 	// 	$mobileServiceInfo.on('animationend', function (e) {
// 	// 		$mobileServiceInfo.removeClass('animate-service-info-left').off();
// 	// 	})
// 	// })
// }

function displayProject (sender) {
	var project = $(sender.currentTarget).data('project');
	$('.our-work-preview').empty().load('./partials/projects/' + project + '.html').fadeIn();
	disableScroll();
}

function changeProject (project, dir) {
	var projectList = ['_neb', '_ram', '_waw', '_bbc', '_sse', '_htd'];
	debugger;
	var index = projectList.indexOf(project);
	var newIndex = index + dir;

	if (newIndex < 0)
		newIndex = projectList.length - 1
	if (newIndex > projectList.length - 1)
		newIndex = 0;

	$('.our-work-preview').empty().load('./partials/projects/' + projectList[newIndex] + '.html');
}

function closeProject () {
	$('.our-work-preview').fadeOut();
	enableScroll();
}

function enableScroll () {
	var $body = $('body');
	$body.bind('mousewheel', pageScroll);
	$body.on('swipeleft', openMenu);
	$body.on('swiperight', closeMenu);
	$body.on('swipeup', nextPage);
	$body.on('swipedown', previousPage);
}

function disableScroll () {
	$('body').off();
}

function randomIntFromInterval (min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function replaceState (page) {
	// Skip executing below in order to get the site to work on localhost
	if (!settings.isDev) {
		history.replaceState(page, page, '/' + page);
	}
}

function closeServicesPopup () {
	$('.mobile-services-info').hide();
	$('.service-title').css('color', '#0c4e68');
}

//
//  script for use in ada compliance popup footer
//


function toggleAnimation () {
	animationEnabled = !animationEnabled;
	$('#playPauseBtn').html(animationEnabled ? 'Animation<br />Pause ❚❚' : 'Animation<br />Play ▶')
}

var filterStates = {
//	contrast: false,
	contrast: 100,
	grayscale: false,
	brightness: 1.0,
	saturation: 100.0
};

function resetSlider (passedThis, value) {
	var elem = document.getElementById(passedThis);
	var id = elem.id;
	elem.value = value;
	switch (id) {
		case 'fontSlider':
			setFontSize(value);
			break;
		case 'brightnessSlider':
			setBrightness(value);
			break;
		case 'contrastSlider':
			setContrast(value);
			break;
		case 'saturationSlider':
			setSaturation(value);
			break;
		default:
			break;
	}
}

function setFontSize (value) {
	var r = document.querySelector(':root');
	var newSize = value * baseFontSize / 50;
	r.style.setProperty('--base-font-size', newSize + 'px');
	var elem = document.getElementById('lblFontSlider');
	newSize = Math.round(((newSize - baseFontSize) * 1000)) / 1000;
	elem.textContent = newSize < 0 ? 'Font Size (' + newSize + ')' : 'Font Size (+' + newSize + ')';
}

function setBrightness (value) {
	if (!isNaN(value)) {
		var newBrightness = value / 50;
		filterStates.brightness = newBrightness;
		var elem = document.getElementById('lblBrightnessSlider');
		newBrightness = Math.round(((newBrightness - 1) * 1000)) / 10;
		elem.textContent = newBrightness < 0 ? 'Brightness (' + newBrightness + ')' : 'Brightness (+' + newBrightness + ')';
		updateFilter();
	}
}

function setSaturation (value) {
	if (!isNaN(value)) {
		filterStates.saturation = value;
		var elem = document.getElementById('lblSaturationSlider');
		var newSaturation = Math.round(((value - 100) * 1000)) / 1000;
		elem.textContent = newSaturation < 0 ? 'Saturation (' + newSaturation + ')' : 'Saturation (+' + newSaturation + ')';
		updateFilter();
	}
}

function setContrast (value) {
	if (!isNaN(value)) {
		filterStates.contrast = value;
		var elem = document.getElementById('lblContrastSlider');
		var newContrast = Math.round(((value - 100) * 1000)) / 1000;
		elem.textContent = newContrast < 0 ? 'Contrast (' + newContrast + ')' : 'Contrast (+' + newContrast + ')';
		updateFilter();
	}
}

function toggleContrast () {
	filterStates.contrast = !filterStates.contrast;
	updateFilter();
}

function toggleGrayscale () {
	filterStates.grayscale = !filterStates.grayscale;
	var elem = document.getElementById('btnToggleGrayScale');
	elem.textContent = filterStates.grayscale === true ? 'Toggle Grayscale Off' : 'Toggle Grayscale On';
	updateFilter();
}

function updateFilter () {
	var adjustment = filterStates.brightness;
	var adjustmentString = 'brightness(' + adjustment + ')';
	var filters = [adjustmentString];

	adjustment = filterStates.saturation;
	adjustmentString = 'saturate(' + adjustment + '%)';
	filters.push(adjustmentString);

	adjustment = filterStates.contrast;
	adjustmentString = 'contrast(' + adjustment + '%)';
	filters.push(adjustmentString);

//	if (filterStates.contrast) {
//		filters.push('contrast(1.3)');
//	}
	if (filterStates.grayscale) {
		filters.push('grayscale(1)');
	}

	$('html').css('filter', filters.join(' '));
}
